import * as React from "react";

const WeAreHiringTabs = () => {
    return (
        <div className="row">
            <div className="col-lg-4 col-xl-3 d-flex justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                <div className="technology-first-col">
                    <div className="d-flex align-items-start">
                        <div className="nav flex-column nav-pills me-lg-3 position-rl" id="v-pills-tab"
                             role="tablist" aria-orientation="vertical">
                            <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#v-pills-home" type="button" role="tab"
                                    aria-controls="v-pills-home" aria-selected="true">PHP
                            </button>
                            <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#v-pills-profile" type="button" role="tab"
                                    aria-controls="v-pills-profile" aria-selected="false">M S TECHNOLOGY
                            </button>
                            <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill"
                                    data-bs-target="#v-pills-messages" type="button" role="tab"
                                    aria-controls="v-pills-messages" aria-selected="false">APP DEVELOPMENT
                            </button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill"
                                    data-bs-target="#v-pills-settings" type="button" role="tab"
                                    aria-controls="v-pills-settings" aria-selected="false">QUALITY ANALYST
                            </button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill"
                                    data-bs-target="#web-designer" type="button" role="tab"
                                    aria-controls="v-pills-settings" aria-selected="false">WEB DESIGNER
                            </button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill"
                                    data-bs-target="#digital-marketing" type="button" role="tab"
                                    aria-controls="v-pills-settings" aria-selected="false">DIGITAL MARKETING
                            </button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill"
                                    data-bs-target="#Business-dev" type="button" role="tab"
                                    aria-controls="v-pills-settings" aria-selected="false">BUSNIESS
                                DEVELOPMENT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-8 col-xl-9">
                <div className="technology-second-col">
                    <div className="tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                             aria-labelledby="v-pills-home-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h4 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                Software Engineer
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h4>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className="">Geeky Bones lab is a technology based company in chandigarh, india. We are looking for a motivated PHP                                                                  Developer to came join our fast-paced and rewarding and rewarding agile team of proffesionals and who is                                                                 dedicated to the craft, write code that they are proud of and can hit the ground running
                                                </p>
                                                <h3  className="mt-lg-4 mt-3">Our Areas of expertise include</h3>
                                                <ul className="carrers-ul-wrap mt-lg-4 mt-3">
                                                    <li>MVC: Cake php, Codeigniter, Zend Framworks, Magento, Druple, YII, Expression engine</li>
                                                    <li>Core Php</li>
                                                    <li>Front-end Developer</li>
                                                </ul>
                                                <h3  className="mt-lg-4 mt-3">Skills and Experience </h3>
                                                <p  className="mt-lg-4 mt-3"> Must have a minimum development experience of 1-4 years in php/core php with mysql and javascript.
                                                    Front-end development include Jquery,ajax,HTMl,css.</p>
                                                <p  className="mt-lg-4 mt-3"> Must have a minimum development experience of 1-4 years in php/core php with mysql and javascript.
                                                    Front-end development include Jquery,ajax,HTMl,css.</p>
                                                <h3  className="mt-lg-4 mt-3">Education</h3>
                                                <p  className="mt-lg-4 mt-3">Btech/Mtech/Bca/MCA</p>
                                                <a href="mailto: contact@geekybones.com" className="gb-btn-md-red-carrier text-center mt-lg-4 mt-3"> Apply Here</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
                             aria-labelledby="v-pills-profile-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                M S TECHNOLOGY
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It
                                                is hidden by default, until the
                                                collapse
                                                plugin adds the appropriate classes that we use to style each
                                                element. These classes control the
                                                overall
                                                appearance, as well as the showing and hiding via CSS
                                                transitions. You can modify any of this with
                                                custom CSS or overriding our default variables. It's also
                                                worth noting that just about any HTML can
                                                go
                                                within the <code>.accordion-body</code>, though the transition
                                                does limit overflow.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
                             aria-labelledby="v-pills-messages-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                APP DEVELOPMENT
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It
                                                is hidden by default, until the
                                                collapse
                                                plugin adds the appropriate classes that we use to style each
                                                element. These classes control the
                                                overall
                                                appearance, as well as the showing and hiding via CSS
                                                transitions. You can modify any of this with
                                                custom CSS or overriding our default variables. It's also
                                                worth noting that just about any HTML can
                                                go
                                                within the <code>.accordion-body</code>, though the transition
                                                does limit overflow.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel"
                             aria-labelledby="v-pills-settings-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                QUALITY ANALYST
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It
                                                is hidden by default, until the
                                                collapse
                                                plugin adds the appropriate classes that we use to style each
                                                element. These classes control the
                                                overall
                                                appearance, as well as the showing and hiding via CSS
                                                transitions. You can modify any of this with
                                                custom CSS or overriding our default variables. It's also
                                                worth noting that just about any HTML can
                                                go
                                                within the <code>.accordion-body</code>, though the transition
                                                does limit overflow.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="web-designer" role="tabpanel"
                             aria-labelledby="v-pills-settings-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                WEB DESINGER
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It
                                                is hidden by default, until the
                                                collapse
                                                plugin adds the appropriate classes that we use to style each
                                                element. These classes control the
                                                overall
                                                appearance, as well as the showing and hiding via CSS
                                                transitions. You can modify any of this with
                                                custom CSS or overriding our default variables. It's also
                                                worth noting that just about any HTML can
                                                go
                                                within the <code>.accordion-body</code>, though the transition
                                                does limit overflow.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="digital-marketing" role="tabpanel"
                             aria-labelledby="v-pills-settings-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                DIGITAL MARKETING
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It
                                                is hidden by default, until the
                                                collapse
                                                plugin adds the appropriate classes that we use to style each
                                                element. These classes control the
                                                overall
                                                appearance, as well as the showing and hiding via CSS
                                                transitions. You can modify any of this with
                                                custom CSS or overriding our default variables. It's also
                                                worth noting that just about any HTML can
                                                go
                                                within the <code>.accordion-body</code>, though the transition
                                                does limit overflow.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="Business-dev" role="tabpanel"
                             aria-labelledby="v-pills-settings-tab">
                            <div className="accordion-wrap">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                Business Development
                                                <h4 className="position-common">No. of Position : 5</h4>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse show"
                                             aria-labelledby="headingThree"
                                             data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <strong>This is the third item's accordion body.</strong> It
                                                is hidden by default, until the
                                                collapse
                                                plugin adds the appropriate classes that we use to style each
                                                element. These classes control the
                                                overall
                                                appearance, as well as the showing and hiding via CSS
                                                transitions. You can modify any of this with
                                                custom CSS or overriding our default variables. It's also
                                                worth noting that just about any HTML can
                                                go
                                                within the <code>.accordion-body</code>, though the transition
                                                does limit overflow.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeAreHiringTabs;

import * as React from "react";
import Layout from "../../components/layout";
import WeAreHiringTabs from "../../components/careers/careers-tabs";
import Breadcrumb from "../../components/common/breadcrumb";

const Index = () => {
  return (
      <Layout headerCustomClass={'gb-header-main-wrapper-inner'} pageTitle={'Careers'} currentPage={'Careers'} breadcrumbView={true} metaTitle={'Build a Meaningful Career | GeekyBones Job Openings'} metaDescription={'We help you bring closer to your career goals! So check out the job opening at GeekyBones and apply accordingly.'}>
          <section className="technology-sec">
              <div className="container">
                  <div className="row">
                      <div className="col-md-1" />
                      <div className="col-md-10 text-center">
                          <div className="common-heading text-center mb-5">
                              <h2>Do <span>What You Love</span> To Do</h2>
                              <p>We are searching for creative and innovative minds to help us build the highest quality mobile applications and develop web-based solutions.</p>
                              <p>Find your role and send your resume at <a href="mailto: contact@geekybones.com">contact@geekybones.com</a></p>
                          </div>
                          <div className="col-md-1" />
                      </div>
                  </div>
              </div>
              <div className="container technology-wrapper">
                  <WeAreHiringTabs />
              </div>
          </section>
      </Layout>
  )
}
export default Index;
